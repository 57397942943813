<template>
  <div id="student">
    <div class="right">
      <div class="title">
        <div class="left_title">
          <div class="img">
            <img src="" alt="" />
          </div>
          <b>课程数据统计</b>
        </div>
        <div class="right_title">导出数据</div>
      </div>
      <div class="right_info">
        <div class="select">
          <el-select
            v-model="option"
            placeholder="请选择班级"
            style="margin-right: 0.2rem"
          >
            <el-option
              label="铁道供电A1901班"
              value="铁道供电A1901班"
            ></el-option>
          </el-select>
        </div>
        <table>
          <tr>
            <td rowspan="2" style="width: 148px">
              <div class="img" style="width: 148px; height: 148px">
                <img
                  src="../../img/登录_slices/图层1.png"
                  alt=""
                  style="width: 100%"
                />
              </div>
            </td>
            <td>学生姓名</td>
            <td>张三</td>
            <td>学生学号</td>
            <td>A190011251</td>
            <td>所属班级</td>
            <td colspan="3">铁道供电技术A1901班</td>
          </tr>
          <tr>
            <td>未签到次数</td>
            <td>0次</td>
            <td>提问次数</td>
            <td>0次</td>
            <td>练习正确率</td>
            <td>94%</td>
            <td>练习排名</td>
            <td>5</td>
          </tr>
        </table>
        <div class="echart">
          <div class="bing" style="width: 324px; height: 324px"></div>
          <div class="zhe" style="width: 787px; height: 324px"></div>
          <div class="ball" style="width: 331px; height: 331px"></div>
          <div class="zhe1" style="width: 787px; height: 324px"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import * as util from "@/unit/network";
import * as echarts from "echarts";

export default {
  data() {
    return {
      option: "",
    };
  },
  mounted() {
    this.bing();
    this.zhe();
    this.ball();
    this.zhe1();
  },
  methods: {
    bing() {
      // console.log(echarts);
      let myBing = echarts.init(document.querySelector(".bing"));
      let option1 = {
        title: {
          text: "签到统计",
          left: "left",
          textStyle: {
            fontSize: 16,
          },
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c}",
        },
        series: [
          {
            name: "签到统计",
            type: "pie",
            radius: "80%",
            data: [
              { value: 39, name: "已签到" },
              { value: 3, name: "未签到" },
            ],
            label: {
              normal: {
                show: true,
                position: "inner",
              },
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(145, 164, 210, 0.4)",
              },
            },
          },
        ],
        color: ["#0062ff", "#05c159"],
      };
      myBing.setOption(option1);
    },
    zhe() {
      let myZhe = echarts.init(document.querySelector(".zhe"));
      let option2 = {
        title: {
          text: "练习正确率统计",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        // legend: {
        //     data: ['正确率']
        // },
        // 下载
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: ["1", "2", "3", "4", "5", "6", "7", ""],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "正确率",
            type: "line",
            stack: "总量",
            // areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: [27, 48, 69, 30, 80],
          },
        ],
      };
      myZhe.setOption(option2);
    },
    ball() {
      let myBall = echarts.init(document.querySelector(".ball"));
      let option3 = {
        title: {
          text: "个人技能统计",
          textStyle: {
            color: "#2C92F2",
            fontSize: 16,
          },
          padding: [0, 0, 100, 0],
          left: "center",
          top: "center",
        },
        tooltip: {
          formatter: function (data) {
            return (
              data.seriesName +
              "<br/>" +
              data.name +
              " : " +
              data.value.toFixed(0) +
              ""
            );
          },
        },
        angleAxis: {
          max: 100,
          z: 10,
          // 隐藏刻度线
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        radiusAxis: {
          type: "category",
          data: [
            "",
            "班级排名",
            "签到次数 ",
            "提问次数",
            "练习正确率",
            "考试及格率",
          ],
          z: 10,
          axisLabel: {
            interval: 0,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
        },
        polar: {
          radius: "100%", //图形大小
        },
        series: [
          {
            type: "bar",
            barWidth: "30%",
            label: {
              show: true,
              position: "inside",
            },

            data: [
              {
                value: 100,
                name: "all",
                itemStyle: {
                  normal: {
                    color: {
                      type: "linear",
                      colorStops: [
                        { offset: 1, color: "#16b9f9" },
                        { offset: 0, color: "#126af9" },
                      ],
                    },
                  },
                },
              },
              //   班级排名
              {
                value: 10,
                name: "班级排名",
                itemStyle: {
                  normal: {
                    color: {
                      type: "linear",
                      colorStops: [
                        { offset: 1, color: "#19E1FF" },
                        { offset: 0, color: "#16b9f9" },
                      ],
                    },
                  },
                },
              },
              // 签到次数
              {
                value: 19,
                name: "签到次数",
                itemStyle: {
                  normal: {
                    color: {
                      type: "linear",
                      colorStops: [
                        { offset: 0, color: "#11c23f" },
                        { offset: 1, color: "#11c95f" },
                      ],
                    },
                  },
                },
              },
              // 提问次数
              {
                value: 24,
                name: "提问次数",
                itemStyle: {
                  normal: {
                    color: {
                      type: "linear",
                      colorStops: [
                        { offset: 0, color: "#f24879" },
                        { offset: 1, color: "#f29419" },
                      ],
                    },
                  },
                },
              },
              // 练习正确率
              {
                value: 60,
                name: "练习正确率",
                itemStyle: {
                  normal: {
                    color: {
                      type: "linear",
                      colorStops: [
                        { offset: 0, color: "#2511f1" },
                        { offset: 1, color: "#2570f4" },
                      ],
                    },
                  },
                },
              },
              // 考试及格率
              {
                value: 70,
                name: "考试及格率",
                itemStyle: {
                  normal: {
                    color: {
                      type: "linear",
                      colorStops: [
                        { offset: 0, color: "#09c0c3" },
                        { offset: 1, color: "#09c6b3" },
                      ],
                    },
                  },
                },
              },
            ],
            coordinateSystem: "polar",
            roundCap: true,
            name: "个人技能",
            z: 2,
          },
          {
            name: "背景1",
            type: "pie",
            radius: ["0", "20%"],
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [
              {
                value: 0,
                hoverAnimation: false,
                itemStyle: {
                  normal: {
                    color: "#E8EDF4",
                  },
                },
              },
            ],
            z: 3,
          },
        ],
      };
      myBall.setOption(option3);
    },
    zhe1() {
      let myZhe = echarts.init(document.querySelector(".zhe1"));
      let option2 = {
        title: {
          text: "班级排名统计",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: ["1", "2", "3", "4", "5", "6", "7", ""],
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "正确率",
            type: "line",
            stack: "总量",
            // areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: [27, 48, 69, 30, 80],
          },
        ],
      };
      myZhe.setOption(option2);
    },
  },
};
</script>
<style lang="less" scoped>
#student {
  width: 100%;
  height: 100%;
  display: flex;

  .right {
    // display: flex;
    width: 100%;
    .title {
      height: 72px;
      padding: 19px 34px 19px 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cdcdcd;
      .left_title {
        display: flex;
        align-items: center;
        color: #131313;
        font-weight: 500;
        .img {
          width: 26px;
          height: 27px;
          margin-right: 9px;
        }
      }
      .right_title {
        color: #fff;
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: #1c5eff;
        border-radius: 5px;
      }
    }
    .right_info {
      height: calc(100% - 72px);
      background: #fff;
      overflow: hidden;
      .select {
        height: 49px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 115px;
        /deep/.el-select {
          width: 176/96in;
        }
        /deep/.el-input__inner {
          height: 27/96in;
        }
        /deep/.el-input__suffix {
          display: flex;
          align-items: center;
        }
      }
      .echart {
        width: 13.6rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 auto;
        margin-top: 22px;
        .bing {
          margin-left: 7px;
        }
        .zhe,
        .zhe1 {
          padding-left: 36px;
          border-left: 1px solid #bebec0;
          margin-right: 52px;
        }
        .zhe1,
        .ball {
          margin-top: 15px;
        }
      }
      table {
        width: 1360px;
        margin-top: 33px;
        margin-left: 65px;
        border-collapse: collapse;
        border: 1px solid #bebec0;
        font-size: 16px;
        tr:first-child td:nth-child(even) {
          background-color: #f8faff;
        }
        tr:last-child td:nth-child(odd) {
          background-color: #f8faff;
        }
        td {
          height: 148/2px;
          text-align: center;
          border-collapse: collapse;
          border: 1px solid #bebec0;
        }
      }
    }
  }
}
.active {
  background-color: #1c5eff;
}
</style>